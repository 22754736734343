import {request} from "@/network/request.js";
import qs from 'qs';

export default{
  // 查询-条件查询供应商对账单汇总信息
  findSupplierReport(data){
    return request({
      method:'POST',
      url:'/report/supplier/findSupplierReport',
      data
    })
  },
  // 查询-条件查询供应商订单列表信息
  findSupplierOrderNoReport(data){
    return request({
      method:'POST',
      url:'/report/supplier/findSupplierOrderNoReport',
      data
    })
  },
  // 查询-条件查询供应商对账单汇总信息
  findSupplierListReport(data){
    return request({
      method:'POST',
      url:'/report/supplier/findSupplierListReport',
      data
    })
  },
}